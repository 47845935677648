import React, { useState, useEffect } from "react";
import { Layout } from "../components/Layout";
import { HeaderBuffer } from "../components/Layout/Header";
// https://github.com/ericz1803/react-google-calendar
import Calendar from "@ericz1803/react-google-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useCalendarEvents, API_KEY, CAL_ID } from "../utils/useCalendarEvents";
import { formatEventDate } from "../utils/calendar-utils";

const EventsPage = () => {
  const { loading, errorMessage, upcomingEvents, cancelledEvents } =
    useCalendarEvents();
  
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const eventId = query.get('id');
    if(!loading && !errorMessage && eventId) {
      const event = document.getElementById(eventId);
      if(event) {
        event.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [loading, errorMessage]);

  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-5xl font-serif text-left">
            Event Calendar
          </h1>
        </div>
        {loading ? (
          <div className="text-center p-8">Loading...</div>
        ) : errorMessage ? (
          <div className="text-center p-8">{errorMessage}</div>
        ) : (
          <EventsContent
            events={upcomingEvents}
            cancelledEvents={cancelledEvents}
          />
        )}
      </main>
    </Layout>
  );
};

function EventsContent({ events, cancelledEvents }) {
  const [activeTab, setActiveTab] = useState("list");
  return (
    <div className="p-8 flex flex-col">
      <div className="flex flex-row mb-4 space-x-2">
        <button
          type="button"
          onClick={() => setActiveTab("list")}
          className="py-2 px-8 font-bold text-cyan-900 hover:bg-gray-200 rounded-lg data-[active=true]:bg-gray-200"
          data-active={activeTab === "list"}
        >
          List View
        </button>
        <button
          type="button"
          onClick={() => setActiveTab("calendar")}
          className="py-2 px-8 font-bold text-cyan-900 hover:bg-gray-200 rounded-lg data-[active=true]:bg-gray-200"
          data-active={activeTab === "calendar"}
        >
          Calendar View
        </button>
      </div>
      {activeTab === "calendar" && (
        <Calendar
          apiKey={API_KEY}
          calendars={[
            {
              calendarId: CAL_ID,
              color: "#215881",
            },
          ]}
          showFooter={false}
        />
      )}
      {activeTab === "list" &&
        (events.length === 0 ? (
          <div className="text-gray-500 text-lg">
            No upcoming events, check back later!
          </div>
        ) : (
          <div className="flex flex-col w-full md:max-w-3xl space-y-4">
            {events.map((event) => (
              <EventItem
                key={event.id}
                event={event}
                cancelledEvents={cancelledEvents.filter(
                  (e) => e.recurringEventId === event.id
                )}
              />
            ))}
          </div>
        ))}
    </div>
  );
}

function EventItem({ event, cancelledEvents }) {
  const { cancelled, formattedDate } = formatEventDate(event, cancelledEvents);
  return (
    <div id={event.id} className="flex flex-col border-2 border-gray-300 rounded-xl p-4">
      <h4 className="text-3xl font-bold text-sky-600">{event.summary}</h4>
      <div className="text-lg font-semibold text-cyan-900">{formattedDate}</div>
      {cancelled && (
        <div className="text-red-500 font-semibold">
          Note: Upcoming event has been cancelled. Check back next time!
        </div>
      )}
      {event.location && (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            event.location
          )}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 hover:underline"
        >
          <FontAwesomeIcon icon={faLocationDot} size="1x" /> {event.location}
        </a>
      )}
      {event.description && (
        <div
          className="text-gray-500 raw-html"
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
      )}
      {!event.description && (
        <div className="text-gray-300">No description</div>
      )}
    </div>
  );
}

export default EventsPage;
